button.productReviewButton {
  @apply absolute text-quick-view-text-color border border-[#4466B0] bg-[white] rounded-md m-3 z-10 w-10 h-10 lg:w-12 lg:h-12 flex justify-center items-center left-auto hover:opacity-50;
  color: #4466b0;
}

.reviewIcon {
  @apply w-6 h-6;
}

.colorClass {
  @apply relative h-3 w-3 rounded-full border border-opacity-10 cursor-pointer hover:ring-1 ring-primary-button-background ring-offset-1;
}

.selectedColorClass {
  @apply ring-1 ring-primary-button-background;
}

.colorsContainerClass {
  @apply mt-auto flex gap-2 flex-wrap content-center pt-2 items-center pl-1;
}
