.headerMainContainer {
  @apply sticky top-0 z-20 w-full h-auto;
}

.headerContainer {
  @apply flex items-center justify-between shadow;
}

.headerContent {
  @apply relative flex flex-col md:flex-row justify-center items-center w-full;
}

.headerMain {
  @apply flex h-16 px-4 items-center w-full mx-auto justify-between lg:max-w-7xl lg:px-8;
}

.headerEnd {
  @apply flex items-center justify-end;
}

.storeLogoContent {
  @apply w-full flex;
}

.socialMedia {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .socialMedia {
    margin-left: 0.5rem;
    gap: 0.5rem;
  }
}

.rightSection {
  display: flex;
  align-items: center;
  gap: 3rem;
  position: relative;
}

.socialMediaContainer {
  display: flex;
  gap: 1rem;
}

.socialMediaLink {
  color: inherit;
  text-decoration: none;
}

.socialMediaIcon {
  width: 20px;
  height: 20px;

  &:hover {
    color: rgba(255, 255, 255, 0.8); /* 80% white */
  }
}

/* Hide social media on mobile */
@media (max-width: 1024px) {
  .socialMediaContainer {
    display: none; /* Hides the social media icons */
  }
  .divider {
    display: none;
  }
}

.divider {
  width: 2px;
  height: 24px;
  background-color: #4466b0;
  margin: 0 0 0 1.6rem;
  align-self: center;
}
