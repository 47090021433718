.navMain,
.defaultNavMain {
  @apply flex mx-auto px-4 pt-7 max-w-2xl lg:max-w-7xl;
  font-family: 'droid-serif', serif;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
}

.defaultNavMain {
  @apply lg:hidden;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
}

.navContent {
  @apply flex overflow-x-auto;
}

.collectionActive {
  @apply font-medium ring-[#ffffff] bg-gray-50;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
}

.collectionDefault {
  @apply relative whitespace-nowrap border-spring-neutral-20 rounded-lg mr-4 px-3.5 py-2.5 text-sm shadow-sm ring-1 ring-inset ring-[#ffffff];
  color: white;
  background-color: #4466b0;
  transition: background-color 0.3s;
}

.collectionDefault:hover {
  background-color: #223358;
}
