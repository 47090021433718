.container {
  @apply relative flex justify-center items-center top-0 w-full bg-black text-white z-20;
  background-color: #4466b0;
  font-family: 'droid-serif', serif;
  font-style: normal;
}

.message {
  @apply flex text-center w-full items-center font-light h-8 text-spring-xxs justify-center md:text-xs lg:text-sm;
  font-size: 0.75rem;
}

.link {
  @apply underline text-white font-semibold;
}

.iconClass {
  @apply h-5 w-5;
}

.pauseButton {
  @apply absolute right-0 px-4 cursor-pointer;
}
