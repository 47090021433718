.sizeSelector {
  @apply mt-10;
  font-family: 'droid-serif', serif;
  font-weight: 700;
  font-style: normal;
}

.labelContainer {
  @apply flex items-center justify-between text-zinc-400;
}

.optionsContainer {
  @apply grid grid-cols-4 gap-4 mt-2;
}

.pointer {
  @apply cursor-pointer;
}

.activeChecked {
  @apply ring-2 ring-[#4466B0];
  color: white;
  background-color: #4466b0;
}

.group {
  @apply relative flex items-center justify-center rounded-md border border-spring-neutral-20 py-3 px-4 text-sm font-medium uppercase hover:ring-2 hover:ring-primary-button-background focus:outline-none sm:flex-1;
}

.unavailable {
  background: rgb(249 250 251)
    url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><path d='M0 99 L99 0 L100 1 L1 100' fill='rgb(0, 0, 0, 0.4)' /></svg>")
    no-repeat center center;
  background-size: 100% 100%, auto;
  @apply cursor-not-allowed bg-gray-50 hover:ring-0;
}

.opacity {
  @apply opacity-40;
}
