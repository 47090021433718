.mainContainer {
  @apply mx-auto max-w-2xl px-4 py-7 lg:max-w-7xl lg:px-8;
}

.headerContainer {
  @apply col-start-1 row-start-1 flex items-center justify-between;
}

.headerTitle {
  @apply capitalize text-2xl font-normal;
  font-family: 'droid-serif', serif;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
}

.productGrid {
  @apply mt-4 lg:mt-6 grid grid-cols-2 gap-x-5 gap-y-6 lg:grid-cols-4 lg:gap-x-7 lg:gap-y-8;
}

.navMain,
.navContent {
  @apply flex max-w-2xl lg:max-w-max;
  font-family: 'droid-serif', serif;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
}

.navMain {
  @apply overflow-hidden;
}

.navContent {
  @apply overflow-x-auto py-4 pr-80 md:pr-16;
}

.collectionActive {
  @apply relative whitespace-nowrap border-spring-neutral-20 rounded-lg mr-4 px-3.5 py-2.5 text-sm shadow-sm ring-1 ring-inset ring-[#ffffff];
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
}

.collectionDefault {
  @apply relative whitespace-nowrap border-spring-neutral-20 rounded-lg mr-4 px-3.5 py-2.5 text-sm shadow-sm ring-1 ring-inset ring-[#ffffff];
  color: white;
  background-color: #4466b0;
  transition: background-color 0.3s;
}

.collectionDefault:hover {
  background-color: #223358;
}

.viewAllContent {
  @apply w-full flex justify-center mt-10;
}

.productTileContent {
  @apply pb-16;
}
