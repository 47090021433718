.dialogMain {
  @apply relative z-40;
}

.dialogContent {
  @apply fixed inset-0 z-40 flex;
}

.dialogPanel {
  @apply fixed inset-0 flex w-full max-w-xs flex-col bg-white shadow-xl;
  background-color: #000000;
}

.dialogPanelClose {
  @apply flex px-2 pb-2 pt-5;
}

.iconSize {
  @apply h-6 w-6;
}

.closePanelButton {
  @apply inline-flex items-center justify-center rounded-md p-2 text-gray-400;
}

.navMain {
  @apply flex-1 overflow-y-auto pt-5 pb-20 py-5;
  border-top: 1px solid #4466b0;
}

.collectionLink {
  @apply block p-2 font-medium;
  font-family: 'droid-serif', serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.25rem;
  color: white;
}

.collectionContent {
  @apply text-sm leading-6 px-2 py-2;
  border-bottom: 1px solid #4466b0;
}

.collectionDefault {
  @apply whitespace-nowrap;
}

.subCollectionDefault {
  @apply block rounded-md p-2 font-light;
  font-family: 'droid-serif', serif;
  font-weight: 400;
  font-style: normal;
  color: white;
}

.socialMediaIconsContainer {
  @apply h-12 pt-10 items-center flex;
}

.socialMediaLinks {
  @apply pl-4;
  color: white;

  &:hover {
    color: rgba(255, 255, 255, 0.8); /* 80% white */
  }
}

.active {
  @apply font-medium;
}
