/* src/components/YouTubeFeed/index.module.css */

.container {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding: 0px;
  background-color: #4466b0;
}

.text {
  color: white;
  margin-right: 100px;
  padding-left: 100px;
  flex: 1;
}

.text H1 {
  font-family: 'droid-serif', serif;
  font-weight: 700;
  font-style: italic;
  font-size: 3.8rem;
  line-height: 3.5rem;
  text-transform: uppercase;
}

.text p {
  font-family: 'droid-serif', serif;
  font-weight: 400;
  font-style: italic;
  padding-top: 10px;
  font-size: 2.1875rem;
}

.button {
  font-family: 'droid-serif', serif;
  margin-top: 10px;
  padding: 10px 100px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1rem;
}

.button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.videoContainer {
  position: relative;
  width: 50%;
}

.iframe {
  position: relative;
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
}

/* Media query for mobile devices */
@media (max-width: 1024px) {
  .container {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center align items horizontally */
    padding: 0;
  }

  .videoContainer {
    width: 100%; /* Make video container full width */
    margin-bottom: 10px; /* Add space below the video */
    padding: 0px;
    order: 1;
  }

  .text {
    text-align: center; /* Center text for better alignment */
    order: 2;
    padding: 20px;
    margin: 0px;
  }

  .text H1 {
    font-family: 'droid-serif', serif;
    font-weight: 700;
    font-style: italic;
    font-size: 2.75rem;
    line-height: 2.5rem;
    text-transform: uppercase;
  }

  .text p {
    font-family: 'droid-serif', serif;
    font-weight: 400;
    font-style: italic;
    padding-top: 10px;
    font-size: 1.5rem;
  }
}
