.relativeZ40 {
  @apply relative z-40;
}

.fixedInsetFlex {
  @apply fixed inset-0 z-40 flex;
}

.transitionTransformEaseInOutDuration300 {
  @apply transition ease-in-out duration-300 transform;
}

.dialogPanelStyle {
  @apply fixed flex w-full md:max-w-[463px] flex-col bg-white shadow-xl bottom-0 md:bottom-auto md:right-12 rounded-b-md;
}

.dialogTitleContainer {
  @apply flex p-5;
}

.dialogTitleText {
  @apply text-lg font-medium leading-10 text-gray-900 w-full;
}

.buttonStyle {
  @apply flex items-center justify-center border border-gray-200 rounded-2xl text-gray-500 p-2;
}

.iconStyle {
  @apply h-8 w-8;
}

.productListContainer {
  @apply flex-1 overflow-y-auto border-t border-spring-neutral-20 px-5 pb-5;
}

.linkStyle {
  @apply flex flex-1 flex-grow items-center justify-center rounded-xl border border-spring-neutral-20 py-3 md:px-8 text-base font-medium text-gray-800 hover:bg-zinc-100 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2;
}

.iconShoppingBag {
  @apply h-6 w-6 flex-shrink-0 text-gray-800 group-hover:text-gray-500 mr-2;
}

button.linkCheckout {
  @apply flex flex-1 flex-grow items-center justify-center rounded-xl border border-primary-button-border bg-[4466B0] md:px-8 py-3 text-base font-medium text-primary-text-color hover:brightness-[0.85] focus:outline-none focus:ring-2 focus:ring-primary-button-background focus:ring-offset-2 focus:ring-offset-gray-50;
}
