.productHeader {
  @apply flex justify-between w-full mb-6 px-0 lg:mb-0;
}

.productName {
  @apply font-medium;
  font-family: 'droid-serif', serif;
  font-weight: 700;
  font-style: normal;
}

.productPrice {
  @apply text-xl font-medium text-spring-neutral-100;
  font-family: 'droid-serif', serif;
  font-weight: 700;
  font-style: normal;
}

.productType {
  @apply text-spring-neutral-70;
  font-family: 'droid-serif', serif;
  font-weight: 400;
  font-style: normal;
}
